<template>
  <div ref="sn" class="sn">
    <div class="top">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_subsidies_sn1.jpg"/>
    </div>
    <div class="bottom">
      <div class="image">
        <img src="https://cdn.cailu88.com/jingxuanshi/client_subsidies_button@2x.png"
             v-if="channel !== 'weixin' && channel !== 'zfb'" @click="handleGetUrl"/>
      </div>
      <div class="content">
        <div style="padding: 20px 28px">
          <p class="copy">{{ copyContent }}</p>
          <van-button @click="copyText($event)">复制链接</van-button>
          <p class="text">
            <span>复制文案</span>
            <van-icon name="arrow"/>
            <span>好友下单</span>
            <van-icon name="arrow"/>
            <span>你赚佣金</span>
          </p>
        </div>
      </div>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Icon, Toast } from 'vant'
import Loading from '../../components/loading'
import baseUrl from '@/utils/baseUrl'
import clipboard from '@/utils/copy'

const api = require('../../utils/api').api

Vue.use(Button)
Vue.use(Icon)
Vue.use(Toast)

export default {
  name: 'sn',
  data () {
    return {
      isAndroidApp: false,
      isIOSApp: false,
      token: '',
      version: '',
      channel: '',
      url: '',
      copyContent: '苏宁百亿补贴，全民省钱！【点击复制生成专属链接】',
      click: true,
      id: '',
    }
  },
  components: {
    Loading,
  },
  created () {
    this.token = this.$route.query.token || '';
    this.version = this.$route.query.version || '';
    this.channel = this.$route.query.channel || '';
    this.appClient = this.$route.query.appClient || '';
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {

    //复制
    copyText (event) {
      let that = this
      if (!this.click) {
        return false
      }
      this.click = true
      this.$.ajax({
        url: baseUrl.baseUrl + '/item/transfer',
        type: 'get',
        headers: {
          'token': that.token,
          'version': that.version,
          'channel': that.channel,
          'appClient': that.appClient,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          shareScene: true,
          content: 'https://sugs.suning.com/IslbnbDV',
          clientType: 2
        },
        async: false,
        success: function (res) {
          if (res.code != 'USER_UN_LOGIN' && res.code){
            that.click = true
            Toast.success({
              duration: 500, // 持续展示 toast
              message: '复制成功'
            })
            that.copyContent = '苏宁百亿补贴，全民省钱！点击' + res.data.spreadContent;
          } else {
            that.click = true
            that.isLogin()
            return false
          }
        }
      })
      this.click = true
      clipboard(this.copyContent, event)
    },

    // 获取url
    handleGetUrl () {
      if (!this.click) {
        return false
      }
      this.click = false
      if (this.url) {
        this.click = true
        let param = {
          url: this.url
        }
        this.$h5AppLink('openWebview', param)
        return
      }
      this.$axios('/item/transfer', {
        params: {
          content: 'https://sugs.suning.com/IslbnbDV',
          clientType: 2
        },
      }).then(res => {
        this.url = res.data.data.spreadContent
        this.copyContent = '苏宁百亿补贴，全民省钱！点击' + res.data.data.spreadContent;
        this.click = true
        let param = {
          url: this.url
        }
        this.$h5AppLink('openWebview', param)
      }).catch(err => {
        Toast.fail(err.data.message)
        this.click = true
        this.isLogin()
      })
    },

    // 登陆
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/subsidies/sn`
      }
      this.$h5AppLink(event, param)
    },
  },
}
</script>
<style lang="less" scoped>
.sn {
  min-height: 100vh;
  background: #2698b3;

  .top {
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .bottom {
    width: 100%;
    text-align: center;

    .image {
      margin-bottom: 36px;

      img {
        width: 275px;
      }
    }

    .content {
      margin: 0 auto;
      width: 340px;
      background: #FFFFFF;
      border-radius: 12px;

      div {
        display: flex;
        flex-direction: column;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }

      .copy {
        margin-bottom: 20px;
        line-height: 21px;
        text-align: left;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 3; //想显示多少行就给多大值
        //-webkit-box-orient: vertical;
      }

      .van-button {
        margin: 0 auto;
        width: 250px;
        height: 50px;
        background: #2698b3;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        border-radius: 25px;
        border: none;
      }

      .text {
        margin-top: 15px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #2698b3;
        line-height: 20px;

        .van-icon {
          margin: 0 12px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
